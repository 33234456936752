<template>
  <div class="info-about">
    <div class="info-about__text" v-if="langPhrases">
      {{langPhrases.template.description}}
    </div>
  </div>
</template>


<script>

export default {
  name: 'InfoAbout',
  components: {},
  data() {
    return {}
  },
  computed: {
    langPhrases() {
      return this.$store.getters.LANG_PHRASES
    }
  },
  mounted() {
  },
  methods: {},
}
</script>

<style scoped lang="scss">
@import '../assets/styles/vars';

a {
  text-decoration: none;
}

.info-about {
  margin-bottom: 48px;

  &__text {
    @extend %fontInter;
    font-weight: 500;
    font-size: 24px;
    line-height: 40px;
    color: var(--color-text);
    max-width: 300px;
    width: 100%;
  }

  &__link {
    color: var(--color-text);
    text-decoration: none;
  }
}

@media only screen and (max-width: 1150px) {
  .info-about {
    &__text {
      font-size: 20px;
    }
  }
}

@media only screen and (max-width: 980px) {
  .info-about {
    margin-top: auto;
    margin-bottom: 30px;
    padding-left: 48px;
    padding-right: 68px;

    &__text {


    }
  }
}

@media only screen and(max-width: 490px) {
  .info-about {
    padding-left: 20px;

    &__text {
      font-size: 16px;
      line-height: 25px;
    }
  }
}

</style>
