<template>
  <div class="lang" v-if="langPhrases">
    <a href="" class="lang__link "
       @click.prevent="changeLang(item.code)"
       v-for="item in langs"
       :key="item.code"
       :class=" {'lang__link_active': item.code === activeLang } "
    >{{ langPhrases.template.lang[item.code] }}</a>
  </div>
</template>

<script>
export default {
  name: 'Lang',
  props: {},
  components: {},
  data() {
    return {
      langs: [
        {
          code: 'ru'
        },
        {
          code: 'en'
        },
      ]
    }
  },
  computed: {
    activeLang() {
      return this.$store.getters.ACTIVE_LANG;
    },
    langPhrases() {
      return this.$store.getters.LANG_PHRASES
    }
  },
  mounted() {
  },
  destroyed() {
  },
  methods: {
    changeLang(lang) {
      this.$store.dispatch('changeLang', lang);

    }

  },

}
</script>
<style scoped lang="scss">
@import '../assets/styles/vars';

a {
  text-decoration: none;
}

.lang {
  display: flex;

  &__link {
    @extend %fontInter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: var(--color-text);
    opacity: 0.3;
    margin-right: 14px;
    &:hover{
      opacity: 1;
    }
  }

  &__link_active {
    opacity: 1;
  }
}

@media only screen and (max-width: 1150px) {
  .lang {
    &__link {
      font-size: 13px;
    }
  }
}

@media only screen and (max-width: 710px) {
  .lang {
    flex-direction: column-reverse;
    margin-bottom: 164px;

    &__link {
      margin-right: 0;
    }
  }
}


</style>
