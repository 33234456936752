<template>

  <div class="contacts">
    <a href="tel:+74951507095" class="contacts__contact contacts__contact_phone"><span>+7 (495) 150 70 95</span></a>
    <a href="mailto:inform@scid.ru" class="contacts__contact contacts__contact_mail"><span>inform@scid.ru</span></a>
  </div>
</template>


<script>
export default {
  name: 'Contacts',
  components: {},
  data() {
    return {}
  },
  mounted() {

  },
  methods: {},
}
</script>

<style scoped lang="scss">
@import '../assets/styles/vars';

a {
  text-decoration: none;
}

.contacts {
  display: block;
  flex-direction: column;
  width: 100%;

  &__contact {
    @extend %font;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: bold;
    font-size: 60px;
    line-height: 32px;
    color: var(--color-text);
    width: 100%;
    overflow: hidden;
    min-height: 50px;

    & span {
      transition: all .4s linear;
      position: absolute;
      top: 20%;
      left: 0;
    }


  }

  &__contact_phone {
    margin-bottom: 40px;
    position: relative;

    &::after {
      content: '+7(495)150 70 95';
      position: absolute;
      top: 150%;
      left: 0;
      transition: all .4s linear;

    }
  }

  &__contact_mail {
    margin-bottom: 40px;
    position: relative;

    &::after {
      content: 'inform@scid.ru';
      position: absolute;
      top: 150%;
      left: 0;
      transition: all .4s linear;

    }
  }

}

@media only screen and (max-width: 1150px) {
  .contacts {
    &__contact {
      font-size: 44px;
    }
  }
}

@media only screen and (min-width: 980px) {
  .contacts {
    &__contact {
      &:hover {
        & span {
          top: -200%;
        }

        &::after {
          top: 20%;
        }
      }
    }
  }
}

@media only screen and (max-width: 980px) {
  .contacts {
    display: flex;
    flex-direction: initial;

    &__contact {
      margin-bottom: 0;
    }
  }
}

@media only screen and (max-width: 720px) {
  .contacts {
    flex-direction: column;
  }
}

@media only screen and(max-width: 490px) {
  .contacts {
    &__contact {
      font-size: 24px;
      min-height: 30px;
    }
  }
}
</style>
