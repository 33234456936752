import Vue from 'vue'
import VueRouter from 'vue-router'
import home from '@/views/Home.vue'
import VueMeta from 'vue-meta'


Vue.use(VueRouter)
Vue.use(VueMeta)

const routes = [
    {
        path: '/',
        name: 'home',
        component: home
    },
    {
        path: '/cases',
        name: 'cases',
        component: () => import(/* webpackChunkName: "about" */ '../views/Cases.vue')
    },
    {
        path: '/agency',
        name: 'agency',
        component: () => import(/* webpackChunkName: "about" */ '../views/Agency.vue')
    },
    {
        path: '/technologies',
        name: 'technologies',
        component: () => import(/* webpackChunkName: "about" */ '../views/Technologies.vue')
    },
    {
        path: '/clients',
        name: 'clients',
        component: () => import(/* webpackChunkName: "about" */ '../views/Clients.vue')
    },  {
        path: '/contacts',
        name: 'ContactUs',
        component: () => import(/* webpackChunkName: "about" */ '../views/ContactUs.vue')
    },
    {
        path: '/404',
        name: '404',
        component: () => import(/* webpackChunkName: "about" */ '../views/404.vue')
    },
    {
        path: '*',
        redirect: '/404'
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
