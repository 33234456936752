export const PORTFOLIO ='portfolio';
export const I18N ='i18n';
export const SERVICES ='portfolio/services';
export const CATEGORIES ='portfolio/categories';
export const PORTFOLIO_ID =(id) => `portfolio/${id}`;
export const COMPETENCIES ='agency/competencies';
export const TECHNOLOGIES ='technologies';
export const CLIENTS ='clients';
export const CLIENTS_CATEGORIES ='clients/categories';


