<template>
  <div class="home">
    <AsideMain/>
    <div class="home__container">
      <NavList
          @mouse-hover="mouseHover"/>
      <InfoAbout v-if="screenWidth <= 980"/>
      <div class="home__contacts">
        <Contacts v-if="screenWidth <= 980"/>
      </div>
    </div>
  </div>
</template>

<script>
import NavList from '@/components/NavList.vue'
import AsideMain from '@/components/AsideMain.vue'
import InfoAbout from '@/components/InfoAbout.vue'
import Contacts from '@/components/Contacts.vue'

export default {
  name: 'Home',
  metaInfo() {
    if(this.langPhrases) {
      return {
        title: this.langPhrases.seo.main.title,
        meta: [
          { name: 'description', content: this.langPhrases.seo.main.description },
        ]
      }
    }
  },
  components: {
    NavList,
    AsideMain,
    Contacts,
    InfoAbout
  },
  data() {
    return {
      screenWidth: 0,
    }
  },
  computed: {
    langPhrases() {
      return this.$store.getters.LANG_PHRASES
    }
  },
  watch: {},
  beforeMount() {
  },
  mounted() {
    window.addEventListener('resize', this.onResize);
    this.onResize();
    this.mouseHover();
    this.scrollTop();
  },
  destroyed() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    onResize() {
      this.screenWidth = window.innerWidth;
    },
    mouseHover(isItemHovered) {
      if (!isItemHovered) {
        document.querySelector('.cursor-dot').classList.remove('cursor-dot_hover');
        document.querySelector('.cursor-dot').classList.remove('cursor-dot_hover_page_next');
        document.querySelector('.cursor').classList.remove('cursor_hover');
      } else if (isItemHovered) {
        document.querySelector('.cursor-dot').classList.add('cursor-dot_hover');
        document.querySelector('.cursor').classList.add('cursor_hover');

      }
    },
    scrollTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  },


}
</script>

<style lang="scss" scoped>
@import '../assets/styles/vars.scss';

.home {
  display: flex;
  justify-content: space-between;
  //min-height: 100vh;


  &__container {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &__contacts {
    display: flex;
    width: 100%;
    margin-bottom: 0;
  }
}

@media only screen and(max-width: 980px) {
  .home {
    &__contacts {
      padding-left: 48px;
      padding-bottom: 34px;
      width: auto;
    }
  }
}

@media only screen and(max-width: 490px) {
  .home {
    &__contacts {
      padding-left: 20px;
    }
  }
}
</style>
<style lang="scss">


</style>

