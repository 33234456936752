<template>
    <div class="aside">
        <div class="logo logo_theme_black"
             v-if="this.theme ">
            <router-link to="/">
                <img src="../assets/images/logo_white.svg" alt="логотип" class="logo__img">
                <img src="../assets/images/logo_title_white.svg" alt="логотип" class="logo__title"
                     v-if="width >=710">
            </router-link>
        </div>
        <div class="logo "
             v-else-if="!this.theme">
            <router-link to="/">
                <img src="../assets/images/logo_black.svg" alt="логотип" class="logo__img">
                <img src="../assets/images/logo_title_black.svg" alt="логотип" class="logo__title">
            </router-link>
        </div>
        <div class="aside__option-container">
            <InfoAbout v-if="width >=981"/>
            <a href="https://sk.ru/" class="aside__logo" target="_blank"
                 v-if="this.theme">
                <img src="../assets/images/logo_sk_en_white.png" alt="логотип" class="aside__logo-img"
                     v-if="this.activeLang === 'en' ">
                <img src="../assets/images/logo_sk_ru_white.png" alt="логотип" class="aside__logo-img" v-else>
            </a>
          <a href="https://sk.ru/" class="aside__logo" target="_blank"
               v-else-if="!this.theme">
            <img src="../assets/images/logo_sk_en_black.png" alt="логотип" class="aside__logo-img"
                 v-if="this.activeLang === 'en' ">
            <img src="../assets/images/logo_sk_ru_black.png" alt="логотип" class="aside__logo-img" v-else>
          </a>
            <Contacts v-if="width >=981"/>
            <div class="aside__option">
                <div class="aside__theme"
                     v-on:click="changeTheme"
                >
                    <div class="aside__theme-pic"><span></span></div>
                    <p class="aside__theme-title">{{ themeTitle }}</p>
                </div>
                <Lang/>
            </div>
        </div>
    </div>
</template>

<script>
import Contacts from "@/components/Contacts.vue";
import InfoAbout from "@/components/InfoAbout.vue";
import Lang from "@/components/Lang.vue";

export default {
    name: 'AsideMain',

    components: {
        Lang,
        Contacts,
        InfoAbout,
    },
    data() {
        return {
            width: 0,
        }
    },
    computed: {
        theme() {
            return this.$store.getters.THEME;
        },
        themeTitle() {
            return this.$store.getters.THEME_TITLE;
        },
        langPhrases() {
            return this.$store.getters.LANG_PHRASES
        },
        activeLang() {
            return this.$store.getters.ACTIVE_LANG;
        }
    },
    watch: {},
    mounted() {
        window.addEventListener('resize', this.onResize);
        this.onResize();
    },

    destroyed() {
        window.removeEventListener('resize', this.onResize);
    },
    methods: {
        onResize() {
            this.width = window.innerWidth;
            // this.width = window.innerWidth <= 1150;
        },
        changeTheme() {
            if (this.theme) {
                this.$store.dispatch('changeTheme', false)
                this.$store.dispatch('changeThemeTitle', 'White')
            } else if (!this.theme) {
                this.$store.dispatch('changeTheme', true)
                this.$store.dispatch('changeThemeTitle', 'Black')
            }
        },
    },

}
</script>

<style scoped lang="scss">
@import '../assets/styles/vars';

a {
    text-decoration: none;
}


.aside {
    height: 100vh;
    padding-top: 52px;
    //padding-left: 60px;
    padding-bottom: 42px;
    color: var(--color-text);
    max-width: 412px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    max-height: calc(100vh - 94px);
    //margin-right: 80px;

    &__option {
        @extend %fontInter;
        display: flex;
        align-items: center;
        margin-bottom: 0;
    }

    &__theme {
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-right: 32px;

    }

    &__theme-pic {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        border: 1px solid var(--color-text);
        overflow: hidden;
        margin-right: 16px;

        & span {
            display: inline-block;
            width: 50%;
            height: 100%;
            background-color: var(--color-text);
        }
    }

    &__theme-title {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: var(--color-text);
    }

    &__logo {
        display: flex;
        max-width: 111px;
        width: 100%;
        max-height: 80px;
        margin-bottom: 48px;
    }

    &__logo-img {
        display: flex;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

}

.logo {

    align-self: baseline;
    padding-left: 48px;

    & a {
        display: flex;
        align-items: center;
    }

    &__img {
        width: 28px;
        height: 32px;
        object-fit: cover;
        margin-right: 8px;
    }

    &__title {
        width: 50px;
        height: 22px;
    }

    //margin-bottom: 400px;
}

@media only screen and(max-width: 1150px) {
    .aside {
        margin-right: auto;
        min-width: 230px;
    }
}

@media only screen and(max-width: 980px) {
    .aside {
        min-width: auto;
        max-width: 158px;
        width: 100%;
        border-right: 1px solid #393B3E;
        padding-top: 40px;
        padding-bottom: 34px;

        &__theme {
            margin-right: 24px;
        }

        &__theme-pic {
            margin-right: 0;
        }

        &__option {
            justify-content: space-around;
        }

        &__theme-title {
            display: none;
        }

        &__lang-link {
            font-size: 13px;
        }
    }
    .logo {
        align-self: center;
        padding-left: 0;

    }
}

@media only screen and(min-width: 710px) {
    .aside {
        &__title {
            //display: initial;
        }
    }
}

@media only screen and(max-width: 710px) {
    .logo {
        &__title {
            display: none;
        }
    }
    .aside {
        max-width: 72px;

        &__option {
            flex-direction: column-reverse;
        }

        &__lang {
            flex-direction: column-reverse;
        }

        &__lang-link {
            margin-right: 0;
        }

        &__theme {
            margin-right: 0;
            margin-top: 64px;
        }

        &__title {

        }
    }
}

</style>
