import Vue from 'vue'
import Vuex from 'vuex'
import axiosInstanse from "../api/api";
import {
    CATEGORIES,
    CLIENTS,
    CLIENTS_CATEGORIES,
    COMPETENCIES,
    I18N,
    PORTFOLIO,
    PORTFOLIO_ID,
    SERVICES,
    TECHNOLOGIES
} from "../api/apiRoutes";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        list: [
            {
                title: 'Кейсы',
                index: 1,
                path: 'cases',
                code: 'portfolio',
            },
            {
                title: 'Агентство',
                index: 2,
                path: '/agency',
                code: 'agency'
            },
            {
                title: 'Технологии',
                index: 3,
                path: '/technologies',
                code: 'technologies'
            },
            {
                title: 'Клиенты',
                index: 4,
                path: '/clients',
                code: 'clients'
            },
            {
                title: 'Контакты',
                index: 5,
                path: '/contacts',
                code: 'contacts'
            },
        ],
        cases: [],
        isItemHovered: false,
        isItemsHover: false,
        isItemIndexHovered: null,
        activeItemAside: 0,
        theme: true,
        themeTitle: 'Black',
        snowCaseDescription: false,
        splash: true,
        lang: 'ru',
        caseDescription: null,
        preloader: '0',
        servicesList: [],
        categoriesList: [],
        langPhrases: null,
        caseId: null,
        competence: null,
        technologies: null,
        clientsList: [],
        clientsCategories: null,
        crawler: false,
    },
    getters: {
        LIST(state) {
            return state.list;
        },
        IS_ITEM_HOVERED(state) {
            return state.isItemHovered;
        },
        IS_ITEMS_HOVER(state) {
            return state.isItemsHover;
        },
        IS_ITEM_INDEX_HOVERED(state) {
            return state.isItemIndexHovered;
        },
        ACTIVE_NAV_LINK(state) {
            return state.activeItemAside;
        },
        THEME(state) {
            return state.theme;
        },
        THEME_TITLE(state) {
            return state.themeTitle;
        },
        SHOW_DESCRIPTION(state) {
            return state.snowCaseDescription
        },
        SHOW_SPLASH(state) {
            return state.splash
        },
        ACTIVE_LANG(state) {
            return state.lang
        },
        CASES(state) {
            return state.cases
        },
        CASE_DESCRIPTION(state) {
            return state.caseDescription
        },
        PRELOADER(state) {
            return state.preloader
        },
        SERVICES_LIST(state) {
            return state.servicesList
        },
        CATEGORIES_LIST(state) {
            return state.categoriesList
        },
        CLIENTS_CATEGORIES(state) {
            return state.clientsCategories
        },
        LANG_PHRASES(state) {
            return state.langPhrases
        },
        CASE_ID(state) {
            return state.caseId
        },
        COMPETENCE(state) {
            return state.competence
        },
        TECHNOLOGIES(state) {
            return state.technologies
        },
        CLIENTS_LIST(state) {
            return state.clientsList
        },
        GET_CRAWLER(state) {
            return state.crawler
        }
    },
    mutations: {
        SET_ITEM_HOVERED(state, bool) {
            return this.state.isItemHovered = bool;
        },
        SET_ITEMS_HOVER(state, bool) {
            return this.state.isItemsHover = bool;
        },
        SET_ITEM_HOVERED_INDEX(state, index) {
            return this.state.isItemIndexHovered = index;
        },
        SET_ACTIVE_NAV_LINK(state, index) {
            return this.state.activeItemAside = index
        },
        SET_THEME(state, bool) {
            return this.state.theme = bool
        },
        SET_THEME_TITLE(state, title) {
            return this.state.themeTitle = title
        },
        SET_SHOW_DESCRIPTION(state, bool) {
            return this.state.snowCaseDescription = bool
        },
        SET_SPLASH(state, bool) {
            return this.state.splash = bool
        },
        SET_LANG(state, lang) {
            return this.state.lang = lang;
        },
        FETCH_CASES(state, cases) {
            return this.state.cases = cases
        },
        FETCH_SERVICES_LIST(state, list) {
            return this.state.servicesList = list
        },
        FETCH_CATEGORIES_LIST(state, list) {
            return this.state.categoriesList = list
        },
        FETCH_CLIENTS_CATEGORIES(state, list) {
            return this.state.clientsCategories = list
        },
        FETCH_CASE_DESCRIPTION(state, caseDescription) {
            return this.state.caseDescription = caseDescription
        },
        SET_PRELOADER_INCR() {
            return this.state.preloader++;
        },
        SET_PRELOADER_DECR() {
            return this.state.preloader--;
        },
        FETCH_LANG_PHRASES(state, phrases) {
            return this.state.langPhrases = phrases;
        },
        SET_CASE_ID(state, caseId) {
            return this.state.caseId = caseId;
        },
        FETCH_COMPETENCE(state, competence) {
            return this.state.competence = competence
        },
        FETCH_TECHNOLOGIES(state, technologies) {
            return this.state.technologies = technologies
        },
        FETCH_CLIENTS_LIST(state, clientsList) {
            return this.state.clientsList = clientsList
        },
        SET_CRAWLER (state, payload) {
            return this.state.crawler = payload
        }

    },
    actions: {
        onHover(context, payload) {
            context.commit('SET_ITEM_HOVERED_INDEX', payload);
            context.commit('SET_ITEMS_HOVER', true);
        },
        mouseOut(context, payload) {
            context.commit('SET_ITEM_HOVERED_INDEX', payload);
            context.commit('SET_ITEMS_HOVER', false);
            context.commit('SET_ITEM_HOVERED', false);
        },
        clickNavLink(context, payload) {
            context.commit('SET_ACTIVE_NAV_LINK', payload)
        },
        changeTheme(context, payload) {
            context.commit('SET_THEME', payload)
        },
        changeThemeTitle(context, payload) {
            context.commit('SET_THEME_TITLE', payload)
        },
        showCaseDescription(context, payload) {
            context.commit('SET_SHOW_DESCRIPTION', payload)
        },
        setSplash(context, payload) {
            context.commit('SET_SPLASH', payload)
        },
        changeLang(context, payload) {
            axiosInstanse.defaults.headers.common['Accept-Language'] = payload;
            context.commit('SET_LANG', payload);
            this.dispatch('fetchLangPhrases');
        },
        fetchPortfolio(context, payload) {
            return axiosInstanse.get(PORTFOLIO, {params: payload})
                .then(this.dispatch('getCrawler'))
                .then(this.dispatch('launchPreloaderIncr'))
                .then(res => {
                    context.commit('FETCH_CASES', res.data.result)
                })
                .catch(err => console.log(err))
                .finally(this.dispatch('launchPreloaderDecr'));
        },
        fetchPortfolioDescription(context, descriptionId) {
            return axiosInstanse.get(PORTFOLIO_ID(descriptionId))
                .then(this.dispatch('getCrawler'))
                .then(this.dispatch('launchPreloaderIncr'))
                .then(res => {
                    context.commit('FETCH_CASE_DESCRIPTION', res.data.result)
                })
                .catch(err => console.log(err))
                .finally(this.dispatch('launchPreloaderDecr'));
        },
        fetchServicesList(context) {
            return axiosInstanse.get(SERVICES)
                .then(this.dispatch('getCrawler'))
                .then(this.dispatch('launchPreloaderIncr'))
                .then(res => {
                    context.commit('FETCH_SERVICES_LIST', res.data.result)
                })
                .catch(err => console.log(err))
                .finally(this.dispatch('launchPreloaderDecr'));
        },
        fetchCategoriesList(context) {
            return axiosInstanse.get(CATEGORIES)
                .then(this.dispatch('getCrawler'))
                .then(this.dispatch('launchPreloaderIncr'))
                .then(res => {
                    context.commit('FETCH_CATEGORIES_LIST', res.data.result)
                })
                .catch(err => console.log(err))
                .finally(this.dispatch('launchPreloaderDecr'));
        },
        fetchLangPhrases(context) {
            return axiosInstanse.get(I18N)
                .then(this.dispatch('getCrawler'))
                .then(this.dispatch('launchPreloaderIncr'))
                .then(res => {
                    context.commit('FETCH_LANG_PHRASES', res.data.result)
                })
                .catch(err => console.log(err))
                .finally(this.dispatch('launchPreloaderDecr'));
        },
        fetchCompetencies(context) {
            return axiosInstanse.get(COMPETENCIES)
                .then(this.dispatch('getCrawler'))
                .then(this.dispatch('launchPreloaderIncr'))
                .then(res => {
                    context.commit('FETCH_COMPETENCE', res.data.result)
                })
                .catch(err => console.log(err))
                .finally(this.dispatch('launchPreloaderDecr'));
        },
        fetchTechnologies(context) {
            return axiosInstanse.get(TECHNOLOGIES)
                .then(this.dispatch('getCrawler'))
                .then(this.dispatch('launchPreloaderIncr'))
                .then(res => {
                    context.commit('FETCH_TECHNOLOGIES', res.data.result)
                })
                .catch(err => console.log(err))
                .finally(this.dispatch('launchPreloaderDecr'));
        },
        fetchClientsList(context) {
            return axiosInstanse.get(CLIENTS)
                .then(this.dispatch('getCrawler'))
                .then(this.dispatch('launchPreloaderIncr'))
                .then(res => {
                    context.commit('FETCH_CLIENTS_LIST', res.data.result)
                })
                .catch(err => console.log(err))
                .finally(this.dispatch('launchPreloaderDecr'));
        },
        fetchClientsCategories(context) {
            return axiosInstanse.get(CLIENTS_CATEGORIES)
                .then(this.dispatch('getCrawler'))
                .then(this.dispatch('launchPreloaderIncr'))
                .then(res => {
                    context.commit('FETCH_CLIENTS_CATEGORIES', res.data.result)
                })
                .catch(err => console.log(err))
                .finally(this.dispatch('launchPreloaderDecr'));
        },
        getCrawler(context) {
            let botPattern = "(googlebot|bot|Googlebot-Mobile|Googlebot-Image|Google favicon|Mediapartners-Google|bingbot|slurp|java|wget|curl|Commons-HttpClient|Python-urllib|libwww|httpunit|nutch|phpcrawl|msnbot|jyxobot|FAST-WebCrawler|FAST Enterprise Crawler|biglotron|teoma|convera|seekbot|gigablast|exabot|ngbot|ia_archiver|GingerCrawler|webmon |httrack|webcrawler|grub.org|UsineNouvelleCrawler|antibot|netresearchserver|speedy|fluffy|bibnum.bnf|findlink|msrbot|panscient|yacybot|AISearchBot|IOI|ips-agent|tagoobot|MJ12bot|dotbot|woriobot|yanga|buzzbot|mlbot|yandexbot|purebot|Linguee Bot|Voyager|CyberPatrol|voilabot|baiduspider|citeseerxbot|spbot|twengabot|postrank|turnitinbot|scribdbot|page2rss|sitebot|linkdex|Adidxbot|blekkobot|ezooms|dotbot|Mail.RU_Bot|discobot|heritrix|findthatfile|europarchive.org|NerdByNature.Bot|sistrix crawler|ahrefsbot|Aboundex|domaincrawler|wbsearchbot|summify|ccbot|edisterbot|seznambot|ec2linkfinder|gslfbot|aihitbot|intelium_bot|facebookexternalhit|yeti|RetrevoPageAnalyzer|lb-spider|sogou|lssbot|careerbot|wotbox|wocbot|ichiro|DuckDuckBot|lssrocketcrawler|drupact|webcompanycrawler|acoonbot|openindexspider|gnam gnam spider|web-archive-net.com.bot|backlinkcrawler|coccoc|integromedb|content crawler spider|toplistbot|seokicks-robot|it2media-domain-crawler|ip-web-crawler.com|siteexplorer.info|elisabot|proximic|changedetection|blexbot|arabot|WeSEE:Search|niki-bot|CrystalSemanticsBot|rogerbot|360Spider|psbot|InterfaxScanBot|Lipperhey SEO Service|CC Metadata Scaper|g00g1e.net|GrapeshotCrawler|urlappendbot|brainobot|fr-crawler|binlar|SimpleCrawler|Livelapbot|Twitterbot|cXensebot|smtbot|bnf.fr_bot|A6-Indexer|ADmantX|Facebot|Twitterbot|OrangeBot|memorybot|AdvBot|MegaIndex|SemanticScholarBot|ltx71|nerdybot|xovibot|BUbiNG|Qwantify|archive.org_bot|Applebot|TweetmemeBot|crawler4j|findxbot|SemrushBot|yoozBot|lipperhey|y!j-asr|Domain Re-Animator Bot|AddThis)";
            let re = new RegExp(botPattern, 'i');
            let userAgent = navigator.userAgent;
            if (re.test(userAgent)) {
                context.commit('SET_CRAWLER', true);
            } else {
                context.commit('SET_CRAWLER', false);
            }
        },
        launchPreloaderIncr(context) {
            if(!this.getters.GET_CRAWLER) {
                context.commit('SET_PRELOADER_INCR')
            }
        },
        launchPreloaderDecr(context) {
            if(!this.getters.GET_CRAWLER) {
                context.commit('SET_PRELOADER_DECR')
            }
        }
    },
    modules: {}
})
