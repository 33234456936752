<template>
  <div class="nav-list">
    <ul v-if="width <= 1150" class=" nav-list__list nav-list__list_mob">
      <li class="nav-list__item-container"
          v-for="(item, index) in list"
          :key="index+1 "
      >
        <router-link :to="{path:item.path}">
          <div class="nav-list__item nav-list__item_visible">
            <p v-if="langPhrases">{{ langPhrases.menu[item.code] }}</p> <span>{{ item.index }}</span>
          </div>
        </router-link>
      </li>
    </ul>

    <swiper v-else-if="width > 1150" class="mySwiper nav-list__list"
            :options="swiperOption"
    >
      <swiper-slide
          v-for="(item, index) in list"
          :key="index+1 "
      >
        <div class="nav-list__item-container"
             @mouseover="onHover(index);
             $emit('mouse-hover', isItemsHover)"
             @mouseout="
            mouseOut();
             $emit('mouse-hover', isItemsHover)"
        >
          <router-link
              :to="{path:item.path}"
              @click.native="clickNavLink(index);"

          >
            <div class="nav-list__item nav-list__item_visible">
              <p v-if="langPhrases">{{ langPhrases.menu[item.code] }}</p> <span>{{ item.index }}</span>
            </div>
            <div class="nav-list__item nav-list__item_hidden">
              <p v-if="langPhrases">{{ langPhrases.menu[item.code] }}</p> <span>{{ item.index }}</span>
            </div>
          </router-link>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>

import {Swiper, SwiperSlide} from 'vue-awesome-swiper';
import 'swiper/css/swiper.css'

export default {
  name: 'NavList',
  props: {},
  components: {
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      width: 0,
      elem: null,
      swiperOption: {
        initialSlide: 1,
        loop: true,
        slidesPerView: 3.1,
        direction: 'vertical',
        centeredSlides: true,
        spaceBetween: 0,
        mousewheel: true,
        speed: 500,
        breakpoints: {
          1580: {
            slidesPerView: 3.1,
          },
          1390: {
            slidesPerView: 3.5,
          },
          1150: {
            slidesPerView: 4.2,
          },
          990: {
            initialSlide: 0,
            centerMode: false,
            slidesPerView: 5,
          },
          320: {
            centerMode: false,
            initialSlide: 0,
            slidesPerView: 5,
          }
        },
      }

    }
  },
  computed: {
    list() {
      return this.$store.getters.LIST;
    },
    isItemHovered() {
      return this.$store.getters.IS_ITEM_HOVERED;
    },
    isItemsHover() {
      return this.$store.getters.IS_ITEMS_HOVER;
    },
    isItemIndexHovered() {
      return this.$store.getters.IS_ITEM_INDEX_HOVERED;
    },
    langPhrases() {
      return this.$store.getters.LANG_PHRASES
    },
  },
  mounted() {
    window.addEventListener('resize', this.onResize);
    this.onResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    onHover(index) {
      this.$store.dispatch('onHover', index)
    },
    mouseOut() {
      this.$store.dispatch('mouseOut', null)
    },
    onResize() {
      this.width = window.innerWidth;
    },
    clickNavLink(index) {
      // this.isItemHovered = false;
      // this.isItemsHover = false;
      //===================
      this.$store.dispatch('mouseOut', null)
      this.$store.dispatch('clickNavLink', index)
    },
  },

}
</script>

<style scoped lang="scss">
@import '../assets/styles/vars';


.nav-list {
  max-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1688px;
  width: 100%;
  overflow: hidden;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }


  &__list {
    list-style: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;
    padding-left: 200px;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 0;
    }
  }

  &__list_mob {
    @extend %padding-right;
  }

  &__item-container {
    padding: 0;
    padding-right: 40px;

    max-width: 100%;
    width: 100%;
    transition: all .4s linear;
    max-height: 280px;
    height: 100%;

    & a {
      position: relative;
      height: 100%;

      //min-height: 350px;
      min-height: 150px;
      max-height: 300px;
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }

  }

  &__item {
    @extend %font;
    display: flex;
    align-items: baseline;
    font-size: 320px;
    font-style: normal;
    font-weight: bold;
    text-transform: uppercase;
    color: var(--color-text);
    transition: all .4s linear;
    width: 100%;
    position: absolute;

    & p {
      margin: 0;
      padding-top: 12px;
    }

    & span {
      @extend %fontInter;
      display: inline-block;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 40px;
      opacity: .3;
      margin-right: 0;
      margin-left: auto;
      padding-right: 22px;
    }

  }

  &__item_visible {
    top: 0;
    left: 0;
    transition: all .4s linear;
  }

  &__item_hidden {
    top: 130%;
    left: 0;

  }
}

.items-hover {
  opacity: .05;
}

.hover {
  transition: all .4s linear;

  opacity: 1;
  //transform: translateX(-60px);


  & span {
    transition: all .4s linear;
    opacity: 1;
  }
}

@media only screen and (max-width: 1900px) {
  .nav-list {
    &__item-container {
    }

    &__item {
      font-size: 16.5vw;
    }
  }
}

@media only screen and (max-width: 1700px) {
  .nav-list {
    &__list {
      padding-left: 100px;
    }
  }
}

@media only screen and (max-width: 1390px) {
  .nav-list {
    &__item-container a{
      max-height: 220px;
    }
    &__item {
      font-size: 15vw;
    }
  }
}

@media only screen and (max-width: 1290px) {
  .nav-list {
    &__list {
      padding-left: 48px;
    }
  }
}

@media only screen and (max-width: 1150px) {
  .nav-list {
    padding-top: 38px;

    &__list {
      padding-left: 48px;
      padding-right: 20px;
    }

    &__item-container {
      height: auto;

      & a {
        min-height: 150px;
      }
    }

    &__item {
      font-size: 13vw;
    }
  }
}

@media only screen and (min-width: 1150px) {
  .nav-list {
    &__list {
      &:hover {
        .nav-list__item {
          opacity: .05;
        }
      }
    }

    &__item-container {
      &:hover {
        .nav-list__item {
          opacity: 1;
        }

        .nav-list__item_hidden {
          top: 0;
        }

        .nav-list__item_visible {
          top: -100%;

        }
      }
    }
  }
}

@media only screen and (max-width: 980px) {
  .nav-list {
    overflow: auto;
    height: auto;

    &__list {
      margin: 0;
    }

    &__item-container {
      padding-right: 20px;
    }

    &__item-container a {
      min-height: 120px;
    }

    &__item {
      font-size: 120px;

      & span {
        font-size: 14px;
      }
    }
  }
}

@media only screen and (max-width: 720px) {
  .nav-list {
    &__item-container a {
      min-height: 80px;
    }

    &__item {
      font-size: 64px;
    }
  }
}

@media only screen and(max-width: 490px) {
  .nav-list {
    padding-top: 20px;

    &__list {
      padding-left: 20px;
    }
  }
}

</style>
