<template>
  <div id="app">
    <div class="cursor" ref="cursor"></div>
    <div class="cursor-dot" ref="cursorDot"></div>

    <transition name="fade" mode="out-in"
                v-if="!this.splash">
      <router-view/>
    </transition>
    <transition name="fade" mode="out-in"
                v-else>
      <SplashScreen/>
    </transition>
  </div>
</template>
<script>
import SplashScreen from './components/SplashScreen.vue';


export default {
  name: 'Home',
  components: {
    SplashScreen,
  },
  data() {
    return {
      cursor: null,
      cursorDot: null,
      colorTextThemeWhite: '#1F2123',
      backgroundThemeWhite: '#F5F5F5',
      backgroundThemeBlack: '#1F2123',
      colorTextThemeBlack: '#ffffff',
    }
  },
  computed: {
    theme() {
      return this.$store.getters.THEME;
    },
    splash() {
      return this.$store.getters.SHOW_SPLASH;
    },
    getCrawler() {
      return this.$store.getters.GET_CRAWLER;
    }
  },
  watch: {
    theme() {
      this.changeTheme();
    }
  },
  beforeMount() {
  },
  mounted() {
    this.$store.dispatch('fetchLangPhrases');
    this.cursor = this.$refs.cursor;
    this.cursorDot = this.$refs.cursorDot;
    window.addEventListener('mousemove', this.mouseCoord);
    this.disableSplash();
    this.hideSplash();
  },
  beforeDestroy() {
    window.removeEventListener('mousemove', this.mouseCoord);
  },
  methods: {
    mouseCoord(e) {
      let scroll = this.getWindowScroll();
      let x = (e.pageX - scroll.left) + 'px';
      let y = (e.pageY - scroll.top) + 'px';
      this.cursor.style.left = x;
      this.cursor.style.top = y;
      this.cursorDot.style.left = x;
      this.cursorDot.style.top = y;
    },
    getWindowScroll() {
      let doc = document.documentElement;
      return {
        left: (window.pageXOffset || doc.scrollLeft) - (doc.clientLeft || 0),
        top: (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0)
      }
    },
    changeTheme() {
      if (this.theme) {
        document.body.style.setProperty('--color-text', this.colorTextThemeBlack)
        document.body.style.setProperty('--background', this.backgroundThemeBlack)
      } else if (!this.theme) {
        document.body.style.setProperty('--color-text', this.colorTextThemeWhite)
        document.body.style.setProperty('--background', this.backgroundThemeWhite)
      }
    },
    disableSplash() {
      if (this.getCrawler) {
        this.$store.dispatch('setSplash', false);
      }
    },
    hideSplash() {
      if(this.splash) {
        setTimeout(() => {
              this.$store.dispatch('setSplash', false)
            }, 3300
        )
      }
    },
  },

}


</script>

<style lang="scss">
@import './assets/styles/vars';


#app {
  max-width: 1928px;
  margin: 0 auto;
}

:root {
  //--color-text: #ffffff;
  --color-text: #E5E5E5;
  --color-text-theme-white: #1F2123;

  --background: #1F2123;
  --background-btn-theme-white: #FFFFFF;
  --background-theme-white: #F5F5F5;

  --mouse-bg: "url('../assets/images/mouse_icon.svg') no-repeat center";

}

h2 {
  margin: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

body {
  margin: 0;
  padding: 0;
  background: var(--background);
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
}

.fade-enter-active {
  //transition: transform .4s, opacity .8s ease-in;
  transition: all .6s ease-out;
}

.fade-leave-active {
  //transition: transform .6s, opacity .8s ease-out;
  transition: all .6s ease-in;
}

.fade-enter {
  opacity: .05;
  transform: translate(100%)
}

.fade-leave-to {
  opacity: .05;
  transform: translate(-100%)
}


@media screen and(min-width: 980px) {


  .cursor {
    display: block;
    max-width: 30px;
    width: 100%;
    max-height: 30px;
    height: 100%;
    border-radius: 50%;
    padding: 5px;
    /*margin: 4px 0 0 4px;*/
    background: #393B3E;
    opacity: .5;
    position: fixed;
    transform: translate(-50%, -50%);
    transition: all 200ms;
    transition-timing-function: ease-out;
    z-index: 1000;
    pointer-events: none;
    border: 5px transparent;

    &_hover {
      background: transparent;
      max-width: 110px;
      max-height: 110px;
      border: 2px solid #FFFFFF;
    }

  }

  .cursor-dot {
    position: fixed;
    display: block;
    transform: translate(-50%, -50%);
    max-width: 10px;
    max-height: 10px;
    width: 100%;
    height: 100%;
    background: #FFFFFF;
    transition-duration: 200ms;
    transition-timing-function: ease-out;
    border-radius: 50%;
    z-index: 1000;
    pointer-events: none;

    &_hover {
      background: url("./assets/images/mouse_icon.svg") no-repeat center;
      max-width: 120px;
      max-height: 120px;
    }

    &_hover_page_next {
      background: url("./assets/images/mouse_icon.svg") no-repeat center;
      max-width: 120px;
      max-height: 120px;
      transform: rotate(90deg) translate(-50%, 50%);
    }

    &_hover_slide_prev {
      background: url("./assets/images/mouse_icon.svg") no-repeat center;
      max-width: 120px;
      max-height: 120px;
      transform: rotate(180deg) translate(50%, 50%);
    }

    &_hover_blog {
      background: url("./assets/images/blog_hover.svg") no-repeat center;
      background-color: #B3BCC1;
      max-width: 100px;
      max-height: 100px;
    }
  }
}

</style>
