<template>

  <div class="splash-screen">
    <div class="splash-screen__title">
      SCID
    </div>
    <div class="splash-screen__bar"></div>
  </div>
</template>

<script>


export default {
  name: 'SplashScreen',
  props: {},
  components: {},
  data() {
    return {}
  },
  computed: {},
  mounted() {

  },
  destroyed() {

  },
  methods: {},

}
</script>

<style scoped lang="scss">
@import '../assets/styles/vars';

.splash-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  overflow: hidden;

  &__title {
    display: flex;
    color: var(--color-text);
    @extend %font;
    font-size: 49vw;
    line-height: .75;
    animation: title 3s ease ;
    cursor: default;
  }
  &__bar{
    height: 1.2vh;
    background-color: var(--color-text);
    opacity: 1;
    width: 100%;
    animation: bar 3s ease;

  }
}
@keyframes title {
  0% {
    opacity: 0;
    transform: scale(50);
 }
  50%{
    opacity: .5;
    transform: scale(.3)
  }
  100% {
    opacity: 1;
    transform: scale(1)
  }
}
@keyframes bar {
  0% { width: 0}
  100% { width: 100%}
}
@media screen and (max-width: 980px){
  .splash-screen{
    &__bar{
      height: .4vh;
    }
  }
}
</style>
